import originAxios from 'axios'

import qs from 'qs';
export default function axios(option) {
    return new Promise((resolve, reject) => {
        // 1.创建axios的实例

        const instance = originAxios.create({
            baseURL: 'http://www.bjwyebd.cn:11080/wyebd/',
            // baseURL: 'http://172.18.1.29:11080/wyebd/',
            timeout: 6000,
        });
        // 配置请求和响应拦截
        instance.interceptors.request.use(config => {
            // console.log('来到了request拦截success中');
            // 1.当发送网络请求时, 在页面中添加一个loading组件, 作为动画

            // 2.某些请求要求用户必须登录, 在请求头中加token
            if (localStorage.getItem('Authorization')) {
                config.headers.Authorization = localStorage.getItem('Authorization');
            }


            // 3.对请求的参数进行序列化(看服务器是否需要序列化)
            // config.data = qs.stringify(config.data)
            // console.log(config);
            if(config.method=='post'){
                config.data=qs.stringify(config.data);//防止post请求参数无法传到后台
            }
            // 4.等等
            return config
        }, err => {
            // console.log('来到了request拦截failure中');
            return err
        })

        //响应拦截
        instance.interceptors.response.use(response => {
            if(response.method=='post'){
                response.data=qs.stringify(response.data);//防止post请求参数无法传到后台
            }
            return response.data
        }, err => {
            console.log('来到了response拦截failure中');
            console.log(err);
            if (err && err.response) {
                switch (err.response.status) {
                    case 400:
                        err.message = '请求错误'
                        break
                    case 401:
                        err.message = '未授权的访问'
                        break
                }
            }
            return err
        })

        // 2.传入对象进行网络请求
        instance(option).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
        // originAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    })


}
