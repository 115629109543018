<template>
	<div class="whole" style="">
		<div class="bgwhit">
		    <!--数据1-->
			<div class="title1 allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">气候资料、气候评价、灾害评估、大型活动安全风险评估等数据</div>
			</div>
			<div class="tibottom">
			</div>

			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		               	<th style="width:30%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData1" :key="index" class="thei theii">
		                <td> <p>{{item.dataname}}</p></td>
		                <td> <p>{{item.content}}</p></td>
		                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据二-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">指数预报数据</div>
			</div>
			<div class="tibottom">
			</div>

			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:30%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData2" :key="index" class="thei theii">
	              		<td> <p>{{item.dataname}}</p></td>
		                <td> <p>{{item.content}}</p></td>
		                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据三-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">天气预报数据</div>
			</div>
			<div class="tibottom">
			</div>

			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:30%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData3" :key="index" class="thei theii">
	              		<td> <p>{{item.dataname}}</p></td>
		                <td> <p>{{item.content}}</p></td>
		                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据四-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">格点天气预报数据</div>
			</div>
			<div class="tibottom">
			</div>

			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:30%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData4" :key="index" class="thei theii">
	              		<td> <p>{{item.dataname}}</p></td>
		                <td> <p>{{item.content}}</p></td>
		                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据五-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">地面观测、高空观测、雷达资料、卫星资料等天气实况数据</div>
			</div>
			<div class="tibottom">
			</div>

			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:30%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData5" :key="index" class="thei theii">
						<td> <p>{{item.dataname}}</p></td>
		                <td> <p>{{item.content}}</p></td>
		                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据六-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">风险预警数据</div>
			</div>
			<div class="tibottom">
			</div>

			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		               <th style="width:30%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData6" :key="index" class="thei theii">
	                	<td> <p>{{item.dataname}}</p></td>
		                <td> <p>{{item.content}}</p></td>
		                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据七-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">空气质量实况和预报</div>
			</div>
			<div class="tibottom">
			</div>

			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		              <th style="width:30%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData7" :key="index" class="thei theii">
	               	 	<td> <p>{{item.dataname}}</p></td>
		                <td> <p>{{item.content}}</p></td>
		                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据八-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">辐射资料</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:30%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData8" :key="index" class="thei theii">
	                	<td> <p>{{item.dataname}}</p></td>
		                <td> <p>{{item.content}}</p></td>
		                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
        </div>
    </div>
</template>

<script>
	import {getAllWeather2} from "network/home";
	export default {
	  data() {
	    return {
	      tablehead: ['全选','序号','标题','发布时间'],
	      tableData: [{"num":1,"name":"全国城市天气预警信号",
	      "date1":"实时更新中国境内县级（包含县级）以上城市气象实况","value":"实时"},
	      {"num":2,"name":"全国城市天气预警信号",
	      "date1":"实时更新中国境内县级（包含县级）以上城市气象预警信号","value":"实时"}],
	      tableData1:'',
	      tableData2:'',
	      tableData3:'',
	      tableData4:'',
	      tableData5:'',
	      tableData6:'',
	      tableData7:'',
	      tableData8:'',
	      messageShow:false,//信息提示是否显示
	      message:"",//信息提示内容,
	      loadingShow:false,
	      title: '气象数据服务',
	      selArr:[],
	    };
  	  },
	  components: {

	  },
  mounted() {
   this.getAll();
  },
  beforeMount() {
    this.tableHead = this.tableHeadDay;
  },
  updated() {
  },
  methods: {
  	//数据列表
  	getAll(){
        var that = this;
        getAllWeather2().then( result =>{
        	if(result.describe=="操作成功！"){
        	    that.tableData1=result.datas[5].data;
	            that.tableData2=result.datas[2].data;
	            that.tableData3=result.datas[0].data;
	            that.tableData4=result.datas[3].data;
	            that.tableData5=result.datas[4].data;
	            that.tableData6=result.datas[1].data;
	            that.tableData7=result.datas[6].data;
	            that.tableData8=result.datas[7].data;
            }else if(result.describe=="数据缺失"){
            	that.messageShow=true;
	            that.message="数据缺失，请稍后再试";
            }else{
	            that.messageShow=true;
	            that.message="请求错误，请刷新";
            }

        }).catch(error => {
            console.log(error);
            that.messageShow=true;
            that.message="请求错误，请刷新";
        });

  	},

  }

};

</script>

<style scoped>
	.whole{
		width:90%;margin: 0 auto;
		height: auto;padding: 15px 0 2px 0;
	}
	.allhet{
		height: 40px;
	}
	.title1{
        /*background:#232323;*/

		width:100%;margin-top:5px;
		font-size: 23px;
	}
	.title{
		width:100%;margin-top:30px;
		font-size: 23px;
	}
	.tileft{
			float:left;width:6px;
            background: #779DBF;
		}
	.titext{
        line-height: 38px;
        background:#779DBF;
        color:#ffe100;
		float:left;width:99%;margin-left:5px;font-weight: 800;
	}
	.tibottom{
		width:100%;height: 3px;;margin-top:3px;background: #779DBF;;
	}
	.headcolor{
        color:#000000;
        /*background: #736a27;*/
		font-size: 21px;
	}
	.bgwhit{
		width: 100%;
        /*background: #FFFFFF;*/
		border-radius: 5px ;
        /*border: 2px solid #d1cfdc;*/
	}
	.inptsty{
		height:37px;
		border-radius: 3px;
		border: 1px solid #d4e8f9;
	}
	.inptwitd{
		width:200px
	}
	.inptwitdtime{
		width:400px
	}
	.imged {
         margin-top:10px;
    }
	.weathertitle{
		background: #bfddf7;
		color:#465e74;
		font-size: 23px;
		line-height: 40px;
		height: 40px;
		padding-left:10px;
	}
	.butst1{
		border-radius: 5px ;
		width: 60px;
		background: #dadae159;
		box-shadow: #a6a8b3 0px 0px 1px 1px ;
		outline: none;
	}
	.btbl{
		width: 50px;
	}
	.btblsel{
		width: 80px;
		background: cornflowerblue;
		color:#ffffff;
		border-radius: 5px;
		border: 0;
		height: 30px;
		line-height: 30px;
		margin:0 0 0 20px;
		font-size: 16px;
	}
	.sediv{
		width:100%;height:45px;
	}
	.thidiv{
		text-align: center;
		width:80px ;float: right;margin: 7px 20px 0 0;
		border: 1px solid #d2cdcd;
		border-radius: 0 3px 3px 0;
		line-height: 35px;
		font-size: 19px;
	}
	.thirdiv{
		text-align: center;
		width:80px ;float: right;
		border: 1px solid #d2cdcd;margin: 7px 0 0 0;
		border-right: 0px solid #d2cdcd;
		border-radius: 3px 0 0 3px;line-height: 35px;
		font-size: 19px;
	}
	.fivdiv{
		margin: 8px;
	}
	.seldiv{
		width:100%;float:left ;margin: 7px 0 0 15px;font-weight: 600;
	}
	.selctfont{
		height: 25px;font-size: 18px;
	}
	.thei{ height: 40px;}
	.theii{ min-height: 40px; font-size:19px;}
	.theiii{
		margin: 3px;
	}
	table {
        width: 100%;
        height: auto;
        border: 2px solid #715f5f;
        border-collapse: collapse;
        /*border-collapse:separate; */
        tr {
          padding: 30px;margin: 30px;
          width: 100%;
          height: 30px;
          block:table-row-group;
          th {
          	border: 1px solid #ededed;
          	font-weight: 200;
          	text-align: center;
          }
          td {
          	font-weight: 100;
          	text-align: left;
            border: 1px solid #ededed;

          }
        }
    }
    p{
    	padding: 5px;
    }
</style>
