<template>
    <div class="login-container" style="background-color: #141a48;margin: 0px;overflow: hidden;">
        <div id="canvascontainer" ref='can'></div>

        <div class="ms-title">北京万云易博达</div>
        <div class="ms-login">
            <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="0px" class="demo-ruleForm">
                <el-form-item prop="username">
                    <el-input v-model="loginForm.username" placeholder="用户名"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" placeholder="密码" v-model="loginForm.password" @keyup.enter.native="handleLogin(loginForm.username,loginForm.password)"></el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button  type="primary" @click.native.prevent="handleLogin(loginForm.username,loginForm.password)">登录</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';
    import {getData} from "network/home";
    export default {
        name: 'login',
        data() {
            const validateUsername = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入用户名'))
                } else {
                    callback()
                }
            }
            const validatePass = (rule, value, callback) => {
                if (value.length < 5) {
                    callback(new Error('密码不能小于5位'))
                } else {
                    callback()
                }
            }
            return {
                userToken:"",
                loginForm: {
                    username: '',
                    password: ''
                },
                rules: {
                    username: [{
                        required: true,
                        trigger: 'blur',
                        validator: validateUsername
                    }],
                    password: [{
                        required: true,
                        trigger: 'blur',
                        validator: validatePass
                    }]
                },
                loading: true
            }
        },
        mounted () {
            container = document.createElement( 'div' );
            this.$refs.can.appendChild( container );
            camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 10000 );
            camera.position.z = 1000;
            scene = new THREE.Scene();
            particles = new Array();
            var PI2 = Math.PI * 2;
            var material = new THREE.ParticleCanvasMaterial( {
                color: 0x0078de,
                program: function ( context ) {
                    context.beginPath();
                    context.arc( 0, 0, 1, 0, PI2, true );
                    context.fill();
                }
            } );
            var i = 0;
            for ( var ix = 0; ix < AMOUNTX; ix ++ ) {
                for ( var iy = 0; iy < AMOUNTY; iy ++ ) {
                    particle = particles[ i ++ ] = new THREE.Particle( material );
                    particle.position.x = ix * SEPARATION - ( ( AMOUNTX * SEPARATION ) / 2 );
                    particle.position.z = iy * SEPARATION - ( ( AMOUNTY * SEPARATION ) / 2 );
                    scene.add( particle );
                }
            }
            renderer = new THREE.CanvasRenderer();
            renderer.setSize( window.innerWidth, window.innerHeight );
            container.appendChild( renderer.domElement );
            document.addEventListener( 'mousemove', onDocumentMouseMove, false );
            window.addEventListener( 'resize', onWindowResize, false );
            animate();
        },
        methods: {
            ...mapMutations(['changeLogin']),
            handleLogin(data,data2) {
                getData(data,data2).then( res =>{

                    if(res.statusCode == 0){
                        this.userToken = 'wyebdwz '+res.datas.token;
                        this.changeLogin({ Authorization: this.userToken });  // 将用户token保存到vuex中
                        this.$router.push({path: '/WeatherUp'})
                        alert("登录成功")
                    }else{
                        if (data === '' || data2 === '') {
                            alert('账号或密码不能为空');
                        }else{
                            alert("用户名密码错误")
                        }
                    }
                }).catch(error => {
                    alert('登陆错误');
                    console.log(error);
                });
            }
        },

        destroyed () {
            // cancelAnimationFrame(animate)
            animate();
        }
    }
    var SEPARATION = 100, AMOUNTX = 50, AMOUNTY = 50;
    var container;
    var camera, scene, renderer;
    var particles, particle, count = 0;
    var mouseX = 0, mouseY = 0;
    var windowHalfX = window.innerWidth / 2;
    var windowHalfY = window.innerHeight / 2;
    function onWindowResize() {
        windowHalfX = window.innerWidth / 2;
        windowHalfY = window.innerHeight / 2;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );
    }
    function onDocumentMouseMove( event ) {
        mouseX = event.clientX - windowHalfX;
        mouseY = event.clientY - windowHalfY;
    }

    function onDocumentTouchStart( event ) {
        if ( event.touches.length === 1 ) {
            event.preventDefault();
            mouseX = event.touches[ 0 ].pageX - windowHalfX;
            mouseY = event.touches[ 0 ].pageY - windowHalfY;
        }
    }

    function onDocumentTouchMove( event ) {
        if ( event.touches.length === 1 ) {
            event.preventDefault();
            mouseX = event.touches[ 0 ].pageX - windowHalfX;
            mouseY = event.touches[ 0 ].pageY - windowHalfY;
        }
    }
    function animate() {
        requestAnimationFrame( animate );
        render();
    }

    function render() {
        camera.position.x += ( mouseX - camera.position.x ) * .05;
        camera.position.y += ( - mouseY - camera.position.y ) * .05;
        camera.lookAt( scene.position );
        var i = 0;
        for ( var ix = 0; ix < AMOUNTX; ix ++ ) {
            for ( var iy = 0; iy < AMOUNTY; iy ++ ) {
                particle = particles[ i++ ];
                particle.position.y = ( Math.sin( ( ix + count ) * 0.3 ) * 50 ) + ( Math.sin( ( iy + count ) * 0.5 ) * 50 );
                particle.scale.x = particle.scale.y = ( Math.sin( ( ix + count ) * 0.3 ) + 1 ) * 2 + ( Math.sin( ( iy + count ) * 0.5 ) + 1 ) * 2;
            }
        }
        renderer.render( scene, camera );
        count += 0.1;

    }
</script>
<style>
    .login-container a{color:#0078de;}
    #canvascontainer{
        position: absolute;
        top: 0px;
    }
    .wz-input-group-prepend{
        background-color: #141a48;
        border: 1px solid #2d8cf0;
        border-right: none;
        color:  #2d8cf0;
    }

</style>

<style  lang="scss" scoped>
    .login-container {
        height: 100vh;
        background-color: #2d3a4b;
        .ms-title {
            position: absolute;
            left: 43%;
            top: 50%;
            width: 100%;
            margin-top: -210px;
            font-size: 35px;
            font-weight: bolder;
            color: #fff;
            letter-spacing:3px
        }
        .ms-login {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 300px;
            height: 140px;
            margin: -150px 0 0 -150px;
            padding: 40px;
            border: 1px solid #409EFF;
            border-radius: 15px;
            /*border-radius: 5px;*/
            /*background: #fff;*/
        }

        .login-btn {
            text-align: center;
        }

        .login-btn button {
            width: 100%;
            height: 36px;
          /*  color:#FFFFFF;
            background-color:#00d1b2;*/
        }
        /*.login-btn button:hover {
            color:#FFFFFF;
            background-color:#00d1b2;
        }*/
        .user-defined-style {
            top: 800px;
        }
    }



</style>
