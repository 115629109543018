<template>
	<div class="whole" id="minH">
		<div class="bgwhit">
		    <!--数据1-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">气象服务案例</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del()">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv" @click="update()">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000" >编辑</a>
				</div>
				<div class=" thirdiv" @click="add()">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000" >新增</a>
				</div>
			</div>
			<Modal ref="modal" :show="showAdd" :title="title" @hideModal="hideModal" @submit="submit">
			</Modal>
			<Modal2 ref="modal2" :show="showModify" :title="title" @hideModal="hideModalmo" @submit="submitmo">
			</Modal2>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei">
	              		<th style="width:5%">
	              			<input type="checkbox" id="allbox"class=" width:200px;"
		                		 @click="allcheck()" style="margin-top: 5px;">
	              		</th>
	              		<th style="width:5%">序号</th>
		                <th style="width:70%">标题</th>
		                <th style="width:20%">发布时间</th>
	              </tr>
	              <tr v-for="item in tableData"  class=" thei theii">
	              	<td>
		                <input type="checkbox"name="names"  class=" width:200px;" @click="showsel(item.id)" style="margin-top: 5px;">
	              	</td>
	                <td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.title}}</p></td>
	                <td> <p>{{item.publishtime}}</p></td>
	              </tr>
	            </table>
			</div>
        </div>
    </div>
</template>

<script>
	import Modal from './addItem.vue'
	import Modal2 from './modityItem.vue'
	import {getAllitem1,delwxr} from "network/home";

	export default {
	  data() {
	    return {
	      value1:'', //开始时间
	      tableData: '',
	      messageShow:false,//信息提示是否显示
	      message:"",//信息提示内容,
	      loadingShow:false,
	      showAdd:false,
	      showModify:false,
	      title: '气象服务案例',
	      selArr:[],
	    };
  	  },
	  components: {
	        Modal,Modal2
	  },
  mounted() {
    this.getAll();
  },
  beforeMount() {
    this.tableHead = this.tableHeadDay;
  },
  updated() {
  },
  methods: {
  	//数据列表
  	getAll(){
		var that = this;
		getAllitem1().then( result =>{
	        console.log(result);
            if(result.describe=="操作成功！"){
            	that.tableData=result.datas[0];
            }else if(result.describe=="数据缺失"){
            	that.messageShow=true;
	            that.message="数据缺失，请稍后再试";
            }else if(result.describe=="token已过期"){
	            alert("用户未登录，请先登录");
	            this.$router.push('/Login');
            }else if(result.describe=="用户未登录，请先登录"){
	            alert("用户未登录，请先登录");
	            this.$router.push('/Login');
            }else{
	            that.messageShow=true;
	            that.message="请求错误，请刷新";
            }

        }).catch(error => {
            console.log(error);
            that.messageShow=true;
            that.message="请求错误，请刷新";
        });
        document.getElementById("minH").style.minHeight=( parseFloat(window.screen.height)-406)+"px";
  	},
    //增
    add(){
    	this.showAdd=true;
    	this.$refs.modal.getheight( );
    },
    //修改
    update(){
   		if(this.selArr.length>1){
   			alert("编辑时只能选择一条数据！")
   		}else if(this.selArr.length==0){
   			alert("请选择一条数据！")
   		}else{
   			this.showModify=true;
   			this.$refs.modal2.getheight( );
   			this.$refs.modal2.getAll(this.selArr[0]);
   		}
    },
    hideModal() {
        // 取消弹窗回调
        this.showAdd = false
    },
	hideModalmo() {
        // 取消弹窗回调
        this.showModify = false
    },
    submit() {
        // 确认弹窗回调
        this.showAdd = false;
        this.clear();
        this.getAll();
    },
    submitmo() {
        // 确认弹窗回调
        this.showModify = false;
        this.clear();
        this.getAll();
    },
    //删除多条
    del(){
    	if(this.selArr.length>0){
    		delwxr(this.selArr).then( result =>{
	            alert(result.describe);
	            this.clear();
	            this.getAll();
	        }).catch(error => {
	            console.log(error);
	            that.message="请求错误，请刷新";
	        });
    	}else{
    		alert("请选择需要删除的数据");
    	}

    },
	//选中
	showsel(val){
		if(this.selArr.indexOf(val)>=0){
			  for(var i=0; i<this.selArr.length;i++){
		        if(this.selArr[i]==val){
		            this.selArr.splice(i,1);
			   }
		     }
		}else{
			this.selArr.push(val);
		}
		if(this.selArr.length<this.tableData.length){
			document.getElementById('allbox').checked=false;
		}
	},
	clear(){
		var that=this;
		that.selArr=[];
		that.tableData="";
	},
	//表格全选
	allcheck(){
		var all=document.getElementById("allbox");//获取到点击全选的那个复选框的id
	    var one=document.getElementsByName("names");//获取到复选框的名称
        if(all.checked==true){//因为获得的是数组，所以要循环 为每一个checked赋值
            for(var i=0;i<one.length;i++){
                one[i].checked=true;
            }
            this.selArr=[];
           	for(var i=0; i<this.tableData.length;i++){
			  this.selArr.push(this.tableData[i].id);
			}
        }else{
            for(var j=0;j<one.length;j++){
                one[j].checked=false;
            }
            this.selArr=[];
        }
	},
  }

};

</script>

<style scoped>
	.whole{
        width:1198px;margin: 0 auto;
		height: auto;padding: 15px 0 2px 0;
	}
	.allhet{
		height: 40px;
	}
	.title{
		width:100%;margin-top:5px;
		font-size: 23px;
	}
	.tileft{
			float:left;width:6px;background:#0077b2
		}
	.titext{
		float:left;width:98%;line-height: 30px;margin-left:5px;font-weight: 800;
	}
	.tibottom{
		width:100%;height: 3px;;margin-top:3px;background:#0077b2
	}
	.headcolor{
		background: #97B4CA;
		font-size: 21px;
	}
	.bgwhit{
		width: 100%;background: #FFFFFF;
		border-radius: 5px ;
		border: 2px solid  #d4e8f9;
	}

	.imged {
         margin-top:10px;
    }
	.weathertitle{
		background: #bfddf7;
		color:#465e74;
		font-size: 23px;
		line-height: 40px;
		height: 40px;
		padding-left:10px;
	}
	.butst1{
		border-radius: 5px ;
		width: 60px;
		background: #dadae159;
		box-shadow: #a6a8b3 0px 0px 1px 1px ;
		outline: none;
	}
	.btbl{
		width: 50px;
	}
	.btblsel{
		width: 80px;
		background: cornflowerblue;
		color:#ffffff;
		border-radius: 5px;
		border: 0;
		height: 30px;
		line-height: 30px;
		margin:0 0 0 20px;
		font-size: 16px;
	}
	.sediv{
		width:100%;height:45px;
	}
	.thidiv{
		text-align: center;
		width:80px ;float: right;margin: 7px 20px 0 0;
		border: 1px solid #d2cdcd;
		border-radius: 0 3px 3px 0;
		line-height: 35px;
		font-size: 19px;
		cursor: pointer;
	}
	.thirdiv{
		text-align: center;
		width:80px ;float: right;
		border: 1px solid #d2cdcd;margin: 7px 0 0 0;
		border-right: 0px solid #d2cdcd;
		border-radius: 3px 0 0 3px;line-height: 35px;
		font-size: 19px;
		cursor: pointer;
	}
	.fivdiv{
		margin: 8px;
	}
	.seldiv{
		width:100%;float:left ;margin: 7px 0 0 15px;font-weight: 600;
	}
	.selctfont{
		height: 25px;font-size: 18px;
	}
	.thei{ height: 40px;}
	.theii{ height: 40px; font-size:19px;}
	table {
		text-align: center;
        width: 100%;
        height: auto;
        border: 2px solid #d2cdcd;
        border-collapse: collapse;
        tr {
          width: 100%;
          height: 30px;
          th {
          	border: 1px solid #ededed;
          	font-weight: 200;
          }
          td {
          	font-weight: 100;
            border: 1px solid #ededed;
          }
        }
    }
    p{
    	padding: 5px;
    }

	input[type="checkbox"] {
	    width: 20px;
	    height: 20px;
	}
	td:nth-child(1){text-align: center;}
    td:nth-child(3){text-align: center;}
    td:nth-child(4){text-align: center;}
    td:nth-child(5){text-align: center;}
</style>
<style >
	.inptsty{
		height:37px;
		border-radius: 3px;
		border: 1px solid #d4e8f9;
	}
	.inptwitd{
		width:200px
	}
	.inptwitdtime{
		width:400px
	}
</style>
