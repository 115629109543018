<template>
	<div class="whole" style="">
		<div class="bgwhit">
		    <!--数据1-->
			<div class="title1 allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">风险预警数据</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del('0')">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv" @click="update('0')">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000" >编辑</a>
				</div>
				<div class=" thirdiv" @click="add('0')">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000" >新增</a>
				</div>
			</div>
			<Modal ref="modal" :show="showAdd" :addtype="addtype":title="title" @hideModal="hideModal" @submit="submit">
			</Modal>
			<Modal2  ref="modal2" :show="showModify" :title="title" @hideModal="hideModalmo" @submit="submitmo">
			</Modal2 >
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:5%">
		                	<input type="checkbox" id="all0"class=" width:200px;"
		                		 @click="allcheck(0)" style="margin-top: 5px;">
		                </th>
		               	<th style="width:5%">序号</th>
		               	<th style="width:25%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData1" :key="index" class="thei theii">
	              	<td>
		                <input type="checkbox" name="names0"class=" width:200px;" @click="showsel1(item.id)" style="margin-top: 5px;">
	              	</td>
                	<td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.dataname}}</p></td>
	                <td> <p>{{item.content}}</p></td>
	                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据二-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">指数预报数据</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del('1')">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv"  @click="update('1')">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000">编辑</a>
				</div>
				<div class=" thirdiv"  @click="add('1')">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000">新增</a>
				</div>
			</div>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:5%">
		                	<input type="checkbox" id="all1"class=" width:200px;"
		                		 @click="allcheck(1)" style="margin-top: 5px;">
		                </th>
		               	<th style="width:5%">序号</th>
		               	<th style="width:25%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData2" :key="index" class="thei theii">
	              	<td>
		                <input type="checkbox" name="names1" @click="showsel2(item.id)" style="margin-top: 5px;">
	              	</td>
	                <td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.dataname}}</p></td>
	                <td> <p>{{item.content}}</p></td>
	                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据三-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">天气预报数据</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del('2')">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv" @click="update('2')">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000" >编辑</a>
				</div>
				<div class=" thirdiv" @click="add('2')">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000" >新增</a>
				</div>
			</div>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:5%">
		                	<input type="checkbox" id="all2"class=" width:200px;"
		                		 @click="allcheck(2)" style="margin-top: 5px;">
		                </th>
		               	<th style="width:5%">序号</th>
		               	<th style="width:25%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData3" :key="index" class="thei theii">
	              	<td>
		                <input type="checkbox" name="names2"@click="showsel3(item.id)" style="margin-top: 5px;">
	              	</td>
	                <td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.dataname}}</p></td>
	                <td> <p>{{item.content}}</p></td>
	                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据四-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">格点天气预报数据</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del('3')">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv" @click="update('3')">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000" >编辑</a>
				</div>
				<div class=" thirdiv" @click="add('3')">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000" >新增</a>
				</div>
			</div>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:5%">
		                	<input type="checkbox" id="all3"class=" width:200px;"
		                		 @click="allcheck(3)" style="margin-top: 5px;">
		                </th>
		               	<th style="width:5%">序号</th>
		               	<th style="width:25%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData4" :key="index" class="thei theii">
	              	<td>
		                <input type="checkbox" name="names3"@click="showsel4(item.id)" style="margin-top: 5px;">
	              	</td>
	                <td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.dataname}}</p></td>
	                <td> <p>{{item.content}}</p></td>
	                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据五-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">地面观测、高空观测、雷达资料、卫星资料等天气实况数据</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del('4')">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv" @click="update('4')">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000" >编辑</a>
				</div>
				<div class=" thirdiv" @click="add('4')">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000" >新增</a>
				</div>
			</div>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:5%">
		                	<input type="checkbox" id="all4"class=" width:200px;"
		                		 @click="allcheck(4)" style="margin-top: 5px;">
		                </th>
		               	<th style="width:5%">序号</th>
		               	<th style="width:25%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData5" :key="index" class="thei theii">
	              	<td>
		                <input type="checkbox" name="names4"@click="showsel5(item.id)" style="margin-top: 5px;">
	              	</td>
	                <td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.dataname}}</p></td>
	                <td> <p>{{item.content}}</p></td>
	                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据六-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">气候资料、气候评价、灾害评估、大型活动安全风险评估等数据</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del('5')">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv" @click="update('5')">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000" >编辑</a>
				</div>
				<div class=" thirdiv" @click="add('5')">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000" >新增</a>
				</div>
			</div>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:5%">
		                	<input type="checkbox" id="all5"class=" width:200px;"
		                		 @click="allcheck(5)" style="margin-top: 5px;">
		                </th>
		               	<th style="width:5%">序号</th>
		               	<th style="width:25%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData6" :key="index" class="thei theii">
	              	<td>
		                <input type="checkbox" name="names5"@click="showsel6(item.id)" style="margin-top: 5px;">
	              	</td>
	                <td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.dataname}}</p></td>
	                <td> <p>{{item.content}}</p></td>
	                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据七-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">空气质量实况和预报</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del('6')">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv"  @click="update('6')">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000">编辑</a>
				</div>
				<div class=" thirdiv" @click="add('6')">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000" >新增</a>
				</div>
			</div>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:5%">
		                	<input type="checkbox" id="all6"class=" width:200px;"
		                		 @click="allcheck(6)" style="margin-top: 5px;">
		                </th>
		               	<th style="width:5%">序号</th>
		               	<th style="width:25%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData7" :key="index" class="thei theii">
	              	<td>
		                <input type="checkbox" name="names6"@click="showsel7(item.id)" style="margin-top: 5px;">
	              	</td>
	                <td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.dataname}}</p></td>
	                <td> <p>{{item.content}}</p></td>
	                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
			<!--数据八-->
			<div class="title allhet">
				<div class="tileft allhet"></div>
				<div class="titext allhet">辐射资料</div>
			</div>
			<div class="tibottom">
			</div>
			<div class="sediv">
				<div class=" thidiv" @click="del('7')">
					<img class=""  width="13"  src="~assets/img/delet.png" alt="">
					<a style="color:#000000" >删除</a>
				</div>
				<div class=" thirdiv" @click="update('7')">
					<img class=""  width="13"  src="~assets/img/motify.png" alt="">
					<a style="color:#000000" >编辑</a>
				</div>
				<div class=" thirdiv" @click="add('7')">
					<img class=""  width="13"  src="~assets/img/add.png" alt="">
					<a style="color:#000000" >新增</a>
				</div>
			</div>
			<div class="fivdiv">
				<p v-show="loadingShow" class="loading-box">
	                <img src="../assets/img/loading.gif" alt=""  srcset="">
	                <span>加载中...</span>
	            </p>
	            <p v-if="messageShow" style="text-align:center;">{{message}}</p>

	            <table v-else border="1" cellspacing="0" id="table" cellpadding="0">
	              <tr class="headcolor thei ">
		                <th style="width:5%">
		                	<input type="checkbox" id="all7"class=" width:200px;"
		                		 @click="allcheck(7)" style="margin-top: 5px;">
		                </th>
		               	<th style="width:5%">序号</th>
		               	<th style="width:25%">数据名称</th>
		                <th style="width:55%">数据概况</th>
		                <th style="width:15%">频次</th>
	              </tr>
	              <tr v-for="(item,index) in tableData8" :key="index" class="thei theii">
	              	<td>
		                <input type="checkbox" name="names7"@click="showsel8(item.id)" style="margin-top: 5px;">
	              	</td>
	                <td> <p>{{item.sort}}</p></td>
                	<td> <p>{{item.dataname}}</p></td>
	                <td> <p>{{item.content}}</p></td>
	                <td> <p>{{item.rate}}</p></td>
	              </tr>
	            </table>
			</div>
        </div>
    </div>
</template>

<script>
	import {getAllWeather1,delWeather,getEachWeather} from "network/home";
	export default {
	  data() {
	    return {
	      tablehead: ['全选','序号','标题','发布时间'],
	      tableData: [{"num":1,"name":"全国城市天气预警信号",
	      "date1":"实时更新中国境内县级（包含县级）以上城市气象实况","value":"实时"},
	      {"num":2,"name":"全国城市天气预警信号",
	      "date1":"实时更新中国境内县级（包含县级）以上城市气象预警信号","value":"实时"}],
	      tableData1:'',tableid1:[],
	      tableData2:'',tableid2:[],
	      tableData3:'',tableid3:[],
	      tableData4:'',tableid4:[],
	      tableData5:'',tableid5:[],
	      tableData6:'',tableid6:[],
	      tableData7:'',tableid7:[],
	      tableData8:'',tableid8:[],
	      messageShow:false,//信息提示是否显示
	      message:"",//信息提示内容,
	      loadingShow:false,
	      showAdd:false,
	      showModify:false,
	      title: '气象数据服务',
	      selArr1:[],selArr2:[],selArr3:[],selArr4:[],
	      selArr5:[],selArr6:[],selArr7:[],selArr8:[],
	      addtype:'',//add which table
	      motitype:'',//motify which table
	      deltype:'',//delet which table
	      tabletype:["yj","zsyb","yb","gdyb","tqsk","fxpg","kqskyb","fs"],
	    };
  	  },
	  components: {
	        Modal: resolve => {
		          require(['./addWeather.vue'], resolve)
		       },
	        Modal2 : resolve => {
		          require(['./modityWeather.vue'], resolve)
		        }
	  },
  mounted() {
   		this.getAll();
  },
  beforeMount() {
  },
  updated() {
  },
  methods: {
  	//数据列表---获取所有数据
  	getAll(){
		var that = this;
		getAllWeather1().then( result =>{
            if(result.describe=="操作成功！"){
            	that.tableData1=result.datas[1].data;
	            that.tableData2=result.datas[2].data;
	            that.tableData3=result.datas[0].data;
	            that.tableData4=result.datas[3].data;
	            that.tableData5=result.datas[4].data;
	            that.tableData6=result.datas[5].data;
	            that.tableData7=result.datas[6].data;
	            that.tableData8=result.datas[7].data;
            }else if(result.describe=="数据缺失"){
            	that.messageShow=true;
	            that.message="数据缺失，请稍后再试";
            }else if(result.describe=="token已过期"){
	            alert("用户未登录，请先登录");
	            this.$router.push('/Login');
            }else if(result.describe=="用户未登录，请先登录"){
	            alert("用户未登录，请先登录");
	            this.$router.push('/Login');
            }else{
	            that.messageShow=true;
	            that.message="请求错误，请刷新";
            }

        }).catch(error => {
            console.log(error);
            that.messageShow=true;
            that.message="请求错误，请刷新";
        });
  	},
  	//数据列表---获取所有数据
  	getEach(val){
		var that = this;
		getEachWeather(that.tabletype[val]).then( result =>{
            if(result.describe=="操作成功！"){
            	if(val==0){that.tableData1=result.datas[0].data;}
	            if(val==1){that.tableData2=result.datas[0].data;}
	            if(val==2){that.tableData3=result.datas[0].data;}
	            if(val==3){that.tableData4=result.datas[0].data;}
	            if(val==4){that.tableData5=result.datas[0].data;}
	            if(val==5){that.tableData6=result.datas[0].data;}
	            if(val==6){that.tableData7=result.datas[0].data;}
	            if(val==7){that.tableData8=result.datas[0].data;}
            }else if(result.describe=="数据缺失"){
            	that.messageShow=true;
	            that.message="数据缺失，请稍后再试";
            }else if(result.describe=="token已过期"){
	            alert("用户未登录，请先登录");
	            this.$router.push('/Login');
            }else if(result.describe=="用户未登录，请先登录"){
	            alert("用户未登录，请先登录");
	            this.$router.push('/Login');
            }else{
	            that.messageShow=true;
	            that.message="请求错误，请刷新";
            }

        }).catch(error => {
            console.log(error);
            that.messageShow=true;
            that.message="请求错误，请刷新";
        });
  	},
    //增
    add(val){
    	this.addtype=val;
    	this.showAdd=true;
    },
    hideModal() {
        // 取消弹窗回调
        this.showAdd = false
    },
	hideModalmo() {
        // 取消弹窗回调
        this.showModify = false
    },
    submit() {
        // 确认弹窗回调
        this.showAdd = false;
        this.clear("add");
        this.getEach(this.addtype);
    },
    submitmo() {
        // 确认弹窗回调
        this.showModify = false;
        this.clear("motify");
        this.getEach(this.motitype);
    },
    //删除多条
    del(val){
    	this.deltype=val;
    	var dat="";var leg="";
    	if(val==0){
    		dat=this.selArr1;leg=this.selArr1.length;
    	}
		if(val==1){
			dat=this.selArr2;leg=this.selArr2.length;
		}
		if(val==2){
			dat=this.selArr3;leg=this.selArr3.length;
		}
		if(val==3){
			dat=this.selArr4;leg=this.selArr4.length;
		}
		if(val==4){
			dat=this.selArr5;leg=this.selArr5.length;
		}
		if(val==5){
			dat=this.selArr6;leg=this.selArr6.length;
		}
		if(val==6){
			dat=this.selArr7;leg=this.selArr7.length;
		}
		if(val==7){
			dat=this.selArr8;leg=this.selArr8.length;
		}
		this.deleach(dat,leg,val);


    },
    //删除接口
    deleach(dat,leg,val){
    	if(leg>0){
		   	delWeather(dat).then( result =>{
	            alert(result.describe);
	            this.getEach(this.deltype);
	        }).catch(error => {
	            console.log(error);
	            that.message="请求错误，请刷新";
	        });
    	}else{
    		alert("请选择需要删除的数据");
    	}
   },
    //修改
   	update(val){
   		this.motitype=val;
		var dat="";var leg="";
    	if(val==0){
    		dat=this.selArr1;leg=this.selArr1.length;
    	}
		if(val==1){
			dat=this.selArr2;leg=this.selArr2.length;
		}
		if(val==2){
			dat=this.selArr3;leg=this.selArr3.length;
		}
		if(val==3){
			dat=this.selArr4;leg=this.selArr4.length;
		}
		if(val==4){
			dat=this.selArr5;leg=this.selArr5.length;
		}
		if(val==5){
			dat=this.selArr6;leg=this.selArr6.length;
		}
		if(val==6){
			dat=this.selArr7;leg=this.selArr7.length;
		}
		if(val==7){
			dat=this.selArr8;leg=this.selArr8.length;
		}
		if(leg>1){
   			alert("编辑时只能选择一条数据！")
   		}else if(leg==0){
   			alert("请选择一条数据！")
   		}else{
   			this.showModify=true;
   			this.$refs.modal2.getAll(dat[0],val);
   		}
   	},
	//选中--表格1
	showsel1(id){
		if(this.selArr1.indexOf(id)>=0){
		  for(var i=0; i<this.selArr1.length;i++){
	        if(this.selArr1[i]==id){
	            this.selArr1.splice(i,1);
		   }
	     }
		}else{this.selArr1.push(id);}
		if(this.selArr1.length<this.tableData1.length){
			document.getElementById('all0').checked=false;
		}
	},
	//选中--表格2
	showsel2(id){
		if(this.selArr2.indexOf(id)>=0){
		  for(var i=0; i<this.selArr2.length;i++){
	        if(this.selArr2[i]==id){
	            this.selArr2.splice(i,1);
		   }
	     }
		}else{this.selArr2.push(id);}
		if(this.selArr2.length<this.tableData2.length){
			document.getElementById('all1').checked=false;
		}
	},
	//选中--表格3
	showsel3(id){
		if(this.selArr3.indexOf(id)>=0){
		  for(var i=0; i<this.selArr3.length;i++){
	        if(this.selArr3[i]==id){
	            this.selArr3.splice(i,1);
		   }
	     }
		}else{this.selArr3.push(id);}
		if(this.selArr3.length<this.tableData3.length){
			document.getElementById('all2').checked=false;
		}
	},
	//选中--表格4
	showsel4(id){
		if(this.selArr4.indexOf(id)>=0){
		  for(var i=0; i<this.selArr4.length;i++){
	        if(this.selArr4[i]==id){
	            this.selArr4.splice(i,1);
		   }
	     }
		}else{this.selArr4.push(id);}
		if(this.selArr4.length<this.tableData4.length0){
			document.getElementById('all3').checked=false;
		}
	},
	//选中--表格5
	showsel5(id){
		if(this.selArr5.indexOf(id)>=0){
		  for(var i=0; i<this.selArr5.length;i++){
	        if(this.selArr5[i]==id){
	            this.selArr5.splice(i,1);
		    }
	      }
		}else{this.selArr5.push(id);}
		if(this.selArr5.length<this.tableData5.length){
			document.getElementById('all4').checked=false;
		}
	},
	//选中--表格6
	showsel6(id){
		if(this.selArr6.indexOf(id)>=0){
		  for(var i=0; i<this.selArr6.length;i++){
	        if(this.selArr6[i]==id){
	            this.selArr6.splice(i,1);
		   }
	     }
		}else{this.selArr6.push(id);}
		if(this.selArr6.length<this.tableData6.length){
			document.getElementById('all5').checked=false;
		}
	},
	//选中--表格7
	showsel7(id){
		if(this.selArr7.indexOf(id)>=0){
		  for(var i=0; i<this.selArr7.length;i++){
	        if(this.selArr7[i]==id){
	            this.selArr7.splice(i,1);
		   }
	     }
		}else{this.selArr7.push(id);}
		if(this.selArr7.length<this.tableData7.length){
			document.getElementById('all6').checked=false;
		}
	},
	//选中--表格8
	showsel8(id){
		if(this.selArr8.indexOf(id)>=0){
		  for(var i=0; i<this.selArr8.length;i++){
	        if(this.selArr8[i]==id){
	            this.selArr8.splice(i,1);
		    }
	      }
		}else{this.selArr8.push(id);}
		if(this.selArr8.length<this.tableData8.length){
			document.getElementById('all7').checked=false;
		}
	},

	//获取分组id
	chexkIdeach(val){
		if(val==0){
			this.tableid1=[];
			for(var i=0; i<this.tableData1.length;i++){
			  this.tableid1.push(this.tableData1[i].id);
			}
		}
		if(val==1){
			this.tableid2=[];
			for(var i=0; i<this.tableData2.length;i++){
			  this.tableid2.push(this.tableData2[i].id);
			}
		}
		if(val==2){
			this.tableid3=[];
			for(var i=0; i<this.tableData3.length;i++){
			  this.tableid3.push(this.tableData3[i].id);
			}
		}
		if(val==3){
			this.tableid4=[];
			for(var i=0; i<this.tableData4.length;i++){
			  this.tableid4.push(this.tableData4[i].id);
			}
		}
		if(val==4){
			this.tableid5=[];
			for(var i=0; i<this.tableData5.length;i++){
			  this.tableid5.push(this.tableData5[i].id);
			}
		}
		if(val==5){
			this.tableid6=[];
			for(var i=0; i<this.tableData6.length;i++){
			  this.tableid6.push(this.tableData6[i].id);
			}
		}
		if(val==6){
			this.tableid7=[];
			for(var i=0; i<this.tableData7.length;i++){
			  this.tableid7.push(this.tableData7[i].id);
			}
		}
		if(val==7){
			this.tableid8=[];
			for(var i=0; i<this.tableData8.length;i++){
			  this.tableid8.push(this.tableData8[i].id);
			}
		}
	},
	//全选
	allcheck(val){
		//获取表格位置
		var name1="";var name2="";
		if(val==0){name1="all0";name2="names0"}
		if(val==1){name1="all1";name2="names1"}
		if(val==2){name1="all2";name2="names2"}
		if(val==3){name1="all3";name2="names3"}
		if(val==4){name1="all4";name2="names4"}
		if(val==5){name1="all5";name2="names5"}
		if(val==6){name1="all6";name2="names6"}
		if(val==7){name1="all7";name2="names7"}

		var all=document.getElementById(name1);//获取到点击全选的那个复选框的id
	    var one=document.getElementsByName(name2);//获取到复选框的名称
        if(all.checked==true){//因为获得的是数组，所以要循环 为每一个checked赋值
            for(var i=0;i<one.length;i++){
                one[i].checked=true;
            }
            this.chexkIdeach(val);
            if(val==0){this.selArr1=this.tableid1;}
			if(val==1){this.selArr2=this.tableid2;}
			if(val==2){this.selArr3=this.tableid3;}
			if(val==3){this.selArr4=this.tableid4;}
			if(val==4){this.selArr5=this.tableid5;}
			if(val==5){this.selArr6=this.tableid6;}
			if(val==6){this.selArr7=this.tableid7;}
			if(val==7){this.selArr8=this.tableid8;}
        }else{
            for(var j=0;j<one.length;j++){
                one[j].checked=false;
            }
            this.clearselArr(val);
        }
	},
	clear(val){
		if(val=="add"){
			this.clearselArr(this.addtype);
			this.clearTablevalue(this.addtype);
		}
		if(val=="motify"){
			this.clearselArr(this.motitype);
			this.clearTablevalue(this.motitype)
		}
	},
	clearselArr(val){
			if(val==0){this.selArr1=[];document.getElementById("all0").checked=false;}
			if(val==1){this.selArr2=[];document.getElementById("all1").checked=false;}
			if(val==2){this.selArr3=[];document.getElementById("all2").checked=false;}
			if(val==3){this.selArr4=[];document.getElementById("all3").checked=false;}
			if(val==4){this.selArr5=[];document.getElementById("all4").checked=false;}
			if(val==5){this.selArr6=[];document.getElementById("all5").checked=false;}
			if(val==6){this.selArr7=[];document.getElementById("all6").checked=false;}
			if(val==7){this.selArr8=[];document.getElementById("all7").checked=false;}
	},
	clearTablevalue(val){
		if(val==0){this.tableData1="";this.tableid1=[];}
		if(val==1){this.tableData2="";this.tableid2=[];}
		if(val==2){this.tableData3="";this.tableid3=[];}
		if(val==3){this.tableData4="";this.tableid4=[];}
		if(val==4){this.tableData5="";this.tableid5=[];}
		if(val==5){this.tableData6="";this.tableid6=[];}
		if(val==6){this.tableData7="";this.tableid7=[];}
		if(val==7){this.tableData8="";this.tableid8=[];}
	},
  }

};

</script>

<style scoped>
	.whole{
        width:1198px;margin: 0 auto;
		height: auto;padding: 15px 0 2px 0;
	}
	.allhet{
		height: 40px;
	}
	.title1{
		width:100%;margin-top:10px;
		font-size: 23px;
	}
	.title{
		width:100%;margin-top:5px;
		font-size: 23px;
	}
	.tileft{
			float:left;width:6px;background:#0077b2
		}
	.titext{
		float:left;width:98%;line-height: 30px;margin-left:5px;font-weight: 800;
	}
	.tibottom{
		width:100%;height: 3px;;margin-top:3px;background:#0077b2
	}
	.headcolor{
		background: #97B4CA;
		font-size: 21px;
	}
	.bgwhit{
		width: 100%;background: #FFFFFF;
		border-radius: 5px ;
		border: 2px solid  #d4e8f9;
	}

	.imged {
         margin-top:10px;
    }
	.weathertitle{
		background: #bfddf7;
		color:#465e74;
		font-size: 23px;
		line-height: 40px;
		height: 40px;
		padding-left:10px;
	}
	.butst1{
		border-radius: 5px ;
		width: 60px;
		background: #dadae159;
		box-shadow: #a6a8b3 0px 0px 1px 1px ;
		outline: none;
	}
	.btbl{
		width: 50px;
	}
	.btblsel{
		width: 80px;
		background: cornflowerblue;
		color:#ffffff;
		border-radius: 5px;
		border: 0;
		height: 30px;
		line-height: 30px;
		margin:0 0 0 20px;
		font-size: 16px;
	}
	.sediv{
		width:100%;height:45px;
	}
	.thidiv{
		text-align: center;
		width:80px ;float: right;margin: 7px 20px 0 0;
		border: 1px solid #d2cdcd;
		border-radius: 0 3px 3px 0;
		line-height: 35px;
		font-size: 19px;
		cursor: pointer;
	}
	.thirdiv{
		text-align: center;
		width:80px ;float: right;
		border: 1px solid #d2cdcd;margin: 7px 0 0 0;
		border-right: 0px solid #d2cdcd;
		border-radius: 3px 0 0 3px;line-height: 35px;
		font-size: 19px;
		cursor: pointer;
	}
	.fivdiv{
		margin: 8px;
	}
	.seldiv{
		width:100%;float:left ;margin: 7px 0 0 15px;font-weight: 600;
	}
	.selctfont{
		height: 25px;font-size: 18px;
	}
	.thei{ height: 40px;}
	.theii{ height: 40px; font-size:19px;}
	table {
        width: 100%;
        height: auto;
        border: 2px solid #d2cdcd;
        border-collapse: collapse;
        tr {
          padding: 30px;margin: 30px;
          width: 100%;
          height: 30px;
          block:table-row-group;
          th {
          	border: 1px solid #ededed;
          	font-weight: 200;
          	text-align: center;
          }
          td {
          	font-weight: 100;
          	text-align: left;
            border: 1px solid #ededed;

          }
        }
    }
    td:nth-child(1){text-align: center;}
    td:nth-child(2){text-align:  center;}
    td:nth-child(3){text-align: left;}
    td:nth-child(4){text-align: left;}
    td:nth-child(5){text-align: left;}
   /*// td:nth-child(){text-align: center;}*/
    p{
    	padding: 5px;
    }

	input[type="checkbox"] {
	    width: 20px;
	    height: 20px;
	}


</style>
<style>
	.inptsty{
		height:37px;
		border-radius: 3px;
		border: 1px solid #d4e8f9;
	}
	.inptwitd{
		width:200px
	}
	.inptwitdtime{
		width:400px
	}
</style>
