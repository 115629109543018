<template>
  <div class="tinymce-editor">
    <editor v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onClick="onClick">
    </editor>
  </div>
</template>
<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image'// 插入上传图片插件
import 'tinymce/plugins/media'// 插入视频插件
import 'tinymce/plugins/table'// 插入表格插件
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件
import 'tinymce/plugins/fullscreen'// 全屏插件
import "tinymce/icons/default/icons" //图标
export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
    // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
    baseUrl: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'lists image media table wordcount code lineheight fullscreen'
    },
    toolbar: {     
     type: Array,
     required: false,
      default() {
	       　　// return ['removeformat undo redo |  bullist numlist | outdent indent | forecolor | fullscreen code', 'bold italic blockquote | h2 p  media link | alignleft aligncenter alignright'] // 修改前
	       　　return ['removeformat undo redo  | styleselect  fontsizeselect  fontselect| code lineheight |bullist numlist  outdent indent | forecolor | fullscreen code', 'bold italic blockquote | alignleft aligncenter alignright ']
    　		}
      //type: [String, Array],
     // default: 'undo redo |  formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat'
    }
  },
  data () {
    return {
      init: {
        language_url: `${this.baseUrl}/tinymce/langs/zh_CN.js`,
        language: 'zh_CN',
        skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
        content_css: `${this.baseUrl}/tinymce/skins/content/default/content.css`,
//      min-height: 300,
        branding: false,
        menubar: false,
        toolbar: this.toolbar,
//      toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons',       
        plugins: this.plugins,
        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt", // 第二步
				style_formats: [
			    {
			      title: '行高',
			      items: [
			        {title: '1', styles: { 'line-height': '1' }, inline: 'span'},
			        {title: '1.5', styles: { 'line-height': '1.5' }, inline: 'span'},
			        {title: '2', styles: { 'line-height': '2' }, inline: 'span'},
			        {title: '2.5', styles: { 'line-height': '2.5' }, inline: 'span'},
			        {title: '3', styles: { 'line-height': '3' }, inline: 'span'}
			      ]
			    }
  			],

			  // FontSelect
			  font_formats: `
			    微软雅黑=微软雅黑;
			    宋体=宋体;
			    黑体=黑体;
			    仿宋=仿宋;
			    楷体=楷体;
			    隶书=隶书;
			    幼圆=幼圆;
			    Andale Mono=andale mono,times;
			    Arial=arial, helvetica,
			    sans-serif;
			    Arial Black=arial black, avant garde;
			    Book Antiqua=book antiqua,palatino;
			    Comic Sans MS=comic sans ms,sans-serif;
			    Courier New=courier new,courier;
			    Georgia=georgia,palatino;
			    Helvetica=helvetica;
			    Impact=impact,chicago;
			    Symbol=symbol;
			    Tahoma=tahoma,arial,helvetica,sans-serif;
			    Terminal=terminal,monaco;
			    Times New Roman=times new roman,times;
			    Trebuchet MS=trebuchet ms,geneva;
			    Verdana=verdana,geneva;
			    Webdings=webdings;
			    Wingdings=wingdings,zapf dingbats`,
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
//      images_upload_handler: (blobInfo, success, failure) => {
//      	console.log(blobInfo.base64())
//        const img = 'data:image/jpeg;base64,' + blobInfo.base64()
//        success(img)
//					}
      },
      myValue: this.value
    }
  },
  mounted () {
    tinymce.init({})
  },
  methods: {
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear () {
      this.myValue = ''
    }
  },
  watch: {
    value (newValue) {
      this.myValue = newValue
    },
    myValue (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>