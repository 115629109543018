<template>
    <div class="whole" style="">
        <div class="bgwhit" >
            <div class="title allhet">
                <div class="tileft allhet"></div>
                <div class="titext allhet">公司概况</div>
            </div>
            <div class="tibottom"></div>
            <div class="contentProject">
                <div  v-html="aboutUs"></div>
            </div>


            <div class="title allhet">
                <div class="tileft allhet"></div>
                <div class="titext allhet">联系我们</div>
            </div>
            <div class="tibottom"></div>
            <div class="content">
                <div style="padding:10px;">单位名称：北京万云易博达信息技术有限公司</div>
                <div style="padding:10px;">单位地址：北京市海淀区北洼西里北京市气象局科技楼</div>
                <!--<div style="padding:10px;">商务部门：郑昌燕：15110086342    68400770/71-8060-->
                <!--<p style="white-space:pre;margin-left: 95px;padding:10px;">陈     铮：13910398155</p>-->
            <!--</div>-->
                <img style="padding-left: 10px" src="~assets/img/phone.png">
            </div>
        </div>
    </div>


</template>

<script>
    export default {
        data(){
            return{
                aboutUs: "<p><br/></p><p style=\"text-indent: 43px; line-height: 2em;\">\n" +
                    "    <span style=\"font-size:21px;line-height:150%;font-family:仿宋_GB2312\">北京万云易博达信息技术有限公司属于北京市气象局直属气象科技产业公司（国企），本着合作共赢原则，可提供“北京市气象服务中心”“北京市气候中心”等权威部门授权使用的全国气象数据、气候资料、气候评价等服务，确保数据的权威性和准确性。依托我公司气象大数据云平台，提供基于移动互联网通道的高速数据读取接口，实现系统间数据实时推送。</span>\n" +
                    "</p>\n" +
                    "<p style=\"line-height: 2em;\">\n" +
                    "    <br/>\n" +
                    "</p>"
            }
        },
        methods:{

        },
        mounted(){

        }
    }
</script>

<style  lang="scss"  scoped>
    .whole{
        width:90%;margin: 0 auto;
        height: auto;padding: 15px 0 2px 0;
    }
    .allhet{
        height: 40px;
    }
    .title{
        width:100%;margin-top:5px;
        font-size: 23px;
    }
    .tileft{
        float:left;width:6px;
        background: #779DBF;
    }
    .titext{
        line-height: 38px;
        background:#779DBF;
        color:#ffe100;
        float:left;width:99%;margin-left:5px;font-weight: 800;
    }
    .tibottom{
        width:100%;height: 3px;;margin-top:3px;
        background: #779DBF;
        /*background:#0077b2*/
    }
    .bgwhit{
        width: 100%;
        /*background: #FFFFFF;*/
        border-radius: 5px ;
        /*border: 2px solid  #d4e8f9;*/
    }
    .contentProject{
        margin: 8px;
        border: 2px solid #d2cdcd;
        height: auto;
    }

    .content{
        font-size: 21px;
        margin: 8px;
        border: 2px solid #d2cdcd;
        height: auto;
    }

</style>
