<template>
    <div>
        <Header />
        <div id="v-content" v-bind:style="{minHeight: Height+'px'}"><router-view /></div>
        <Footer/>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                Height: 0
            }
        },
        mounted(){
            //动态设置内容高度 让footer始终居底   header+footer的高度是100
            this.Height = document.documentElement.clientHeight - 160;
            //监听浏览器窗口变化　
            window.onresize = ()=> {this.Height = document.documentElement.clientHeight -160}
        }
    }
</script>

<style >

</style>
