import Vue from 'vue'
import VueRouter from 'vue-router'

import About  from "@/views/About"
import ItemService  from "@/views/ItemService"
import ItemServiceUp  from "@/views/ItemServiceUp"
import Project  from "@/views/Project"
import ProjectUp  from "@/views/ProjectUp"
import TecService  from "@/views/TecService"
import TecServiceUp  from "@/views/TecServiceUp"
import Weather  from "@/views/Weather"
import WeatherUp  from "@/views/WeatherUp"
import Home  from "@/views/Home"
import Homee  from "@/views/Homee"
import Login  from "@/views/Login"

// const About = r => require.ensure([], () => r(require('views/About')),'About')
// const ItemService = r => require.ensure([], () => r(require('views/ItemService')),'ItemService')
// const ItemServiceUp = r => require.ensure([], () => r(require('views/ItemServiceUp')),'ItemServiceUp')
// const Project = r => require.ensure([], () => r(require('views/Project')),'Project')
// const ProjectUp = r => require.ensure([], () => r(require('views/ProjectUp')),'ProjectUp')
// const TecService = r => require.ensure([], () => r(require('views/TecService')),'TecService')
// const TecServiceUp = r => require.ensure([], () => r(require('views/TecServiceUp')),'TecServiceUp')
// const Weather = r => require.ensure([], () => r(require('views/Weather')),'Weather')
// const WeatherUp = r => require.ensure([], () => r(require('views/WeatherUp')),'WeatherUp')
// const Home = r => require.ensure([], () => r(require('views/Home')), 'Home')
// const Homee = r => require.ensure([], () => r(require('views/Homee')), 'Homee')
// const Login = r => require.ensure([], () => r(require('views/Login')),'Login')




Vue.use(VueRouter)



export default new VueRouter({
    routes: [
        // 前台页面路由
        {path: '/Login', name: '登录',component: Login},
        {
            path:'/',
            component:Home,
            redirect:'/Weather',
            children:[{
                path: 'Weather',
                component: Weather
            },
                {
                    path: 'Project',
                    component: Project
                },
                {
                    path: 'ItemService',
                    component: ItemService
                },
                {
                    path: 'TecService',
                    component: TecService
                },
                {
                    path:'About',
                    component:About
                },
            ]
        },

        //后台页面路由
        {
            path:'/',
            component:Homee,
            redirect:'/WeatherUp',
            children:[{
                path: 'WeatherUp',
                component: WeatherUp
            },
                {
                    path: 'ProjectUp',
                    component: ProjectUp
                },
                {
                    path: 'ItemServiceUp',
                    component: ItemServiceUp
                },
                {
                    path: 'TecServiceUp',
                    component: TecServiceUp
                },
            ]
        },
    ]
})
