import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
Vue.config.productionTip = false
import 'default-passive-events'

import Header from 'components/Header'
import Footer from 'components/Footer'


Vue.component('Header',Header)
Vue.component('Footer',Footer)

router.beforeEach((to, from, next) => {
    if (to.path == '/Login' ) {
        next();
    }else if(to.path == '/Weather'||to.path == '/Project' ||to.path == '/ItemService'||to.path == '/TecService'|to.path == '/About'){
        localStorage.removeItem('Authorization');
        next();
    } else {
        let token = localStorage.getItem('Authorization');
        if (token == 'null' || token == '') {
            next('/Login');
        } else {
            next();
        }
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
