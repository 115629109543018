<template>
    <div class="modal-bg" v-show="show" @mousemove="modalMove" @mouseup="cancelMove">
        <div class="modal-container1">
            <div class="modal-header" @mousedown="setStartingPoint">
                <div style="margin: 0 0 0 20px;text-align: left;width:100%;">{{ title }}</div>
            </div>
             <div class="modal-main11">
	    			<div class="maiesef">标</div>
					<div class="maiesef">题：</div>
	    			<input class=" inptsty inptwitdti"required ty ="text" name="" ref="name"id="" value="" />						
	    			<div class="maiesef maieseff">序</div>
					<div class="maiesef">号：</div>
					
	    			<input class=" inptsty  inptwitdti2"   ty ="text" name=""ref="sort" id="" value="" />	
	    			<div class="maiesef2">发布时间：</div>
	    			<div class="maininput">
	        			<el-date-picker 
			            v-model="value1"
			            type="date"
			            format="yyyy-MM-dd "
			            value-format="yyyy-MM-dd"
			            placeholder="选择发布时间">
				    	</el-date-picker>
	        	</div>		
            </div>
            <div class="modal-main2">
            	<div class="maintitle2"><div class="mainleft">案例详情</div></div>
            	<div class="maincontxtall">                   		
            		  <tinymce-editor v-model="msg" 
					      :disabled="disabled"
					      @onClick="onClick"
					      ref="editor"></tinymce-editor>
					   <!-- <button  class="butst1" @click="clear">清空案例内容</button>-->
					   <!-- <button @click="disabled = true">禁用</button>-->
            	</div>
            	
            </div>
            <div class="modal-footer">
                <button round @click="hideModal">取消</button>
                <button type="primary" round @click="submit">提交</button>
            </div>
        </div>
    </div>
</template>
<script>
import TinymceEditor from './TextEdit'
import {getwxOne,updatewxOne,getjsOne,updatejsOne} from "network/home";
export default {
    name: 'modal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },       

    },
    data() {
        return {
            x: 0,
            y: 0,
            node: null,
            isCanMove: false,
            value:new Date(),
            value1: new Date(), //开始时间
            imageUrl:require('@/assets/img/noimage.png'),
            wtype:'',   
            msg: '请输入内容',
     		disabled: false,
     		dateid3:'',
     		dateid4:'',
        }
    },
    mounted() {
        this.node = document.querySelector('.modal-container1');
        this.whichType();
        Date.prototype.Format = function(fmt) {//时间格式化
	      var o = {
	        "M+": this.getMonth() + 1, //月份
	        "d+": this.getDate(), //日
	        "H+": this.getHours(), //小时
	        "m+": this.getMinutes(), //分
	        "s+": this.getSeconds(), //秒
	        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
	        S: this.getMilliseconds() //毫秒
	      };
	      if (/(y+)/.test(fmt))
	        fmt = fmt.replace(
	          RegExp.$1,
	          (this.getFullYear() + "").substr(4 - RegExp.$1.length)
	        );
	      for (var k in o)
	        if (new RegExp("(" + k + ")").test(fmt))
	          fmt = fmt.replace(
	            RegExp.$1,
	            RegExp.$1.length == 1
	              ? o[k]
	              : ("00" + o[k]).substr(("" + o[k]).length)
	          );
	      return fmt;
	    };
	    //获取本地时间并格式化时间，赋给默认时间
	    var DaydefaultTime = new Date().Format("yyyy-MM-dd");
	    this.value1=DaydefaultTime;
	    this.value=DaydefaultTime;
    },
    components: {
	       TinymceEditor
	},  
    methods: {
    	getheight(){
	    	var ds=parseFloat(window.screen.height)*0.95-370;
	    	document.getElementsByClassName('modal-main2')[1].style.height=ds+"px";
	    	var dse=parseFloat(window.screen.height)*0.95-430;
			document.getElementsByClassName('tox tox-tinymce')[1].style.height=dse+"px";
    	},
    	getAll(typid){ 		
    		if(this.wtype==3){
    			getwxOne(typid).then( result =>{
					if(result.describe=="操作成功！"){
						this.$refs.sort.value=result.datas[0].sort;
			            this.$refs.name.value=result.datas[0].title;
		                this.value1=result.datas[0].publishtime;
				        tinyMCE.activeEditor.setContent(result.datas[0].content);
		        		this.dateid3=result.datas[0].id;
					}else if(result.describe=="token已过期"){
		            	alert("用户未登录，请先登录");
		            	this.$router.push('/Login');
	           	 	}else{
				   	 	alert(result.describe+"请重试");
				   	}
		            
		        }).catch(error => {
		            console.log(error);
		            this.message="请求错误，请刷新";
		        });
	    			
	    	}
	    	if(this.wtype==4){
    			getjsOne(typid).then( result =>{
					if(result.describe=="操作成功！"){
						this.$refs.sort.value=result.datas[0].sort;
			            this.$refs.name.value=result.datas[0].title;
		                this.value1=result.datas[0].publishtime;
				        tinyMCE.activeEditor.setContent(result.datas[0].content);
	        			this.dateid3=result.datas[0].id;
					}else if(result.describe=="token已过期"){
		            	alert("用户未登录，请先登录");
		            	this.$router.push('/Login');
	           	 	}else{
				   	 	alert(result.describe+"请重试");
				   	}
		            
		        }).catch(error => {
		            console.log(error);
		            this.message="请求错误，请刷新";
		        });
	    			
	    	}

    	},
    	//鼠标单击的事件
	    onClick(e, editor) {
//	      console.log('Element clicked')
//	      console.log(e)
//	      console.log(editor)
	    },
	    //清空内容
	    clear() {
	      this.$refs.editor.clear()
	    },
    	whichType(){
    		var tp=this._props.title;   		
    		if(tp=="气象服务案例"){this.wtype='3'};
    		if(tp=="技术服务案例"){this.wtype='4'};
    	},
        hideModal() {
        	this.cleatForm();
            this.$emit('hideModal')
        },
		//验证序号
		checkNumber(theObj) {
		  var reg = /^[0-9]+.?[0-9]*$/;
		  if (reg.test(theObj)) {
		    return true;
		  }
		  return false;
		},
        submit() {
        	var s1=this.$refs.sort.value;
        	var s2=this.value1;
        	var s3=this.$refs.name.value;
        	var s4=tinyMCE.activeEditor.getContent();
        	var s5=this.dateid3;
        	if(s1.length==0){
				this.$message.warning('序号不能为空')	
            }else if (!this.checkNumber(s1)) {
				  this.$message.warning('序号只能为数字');
				  return false;
			}else if(s2==null){
				this.$message.warning('发布时间不能为空')	
            }else if(s3.trim().length==0){
				this.$message.warning('标题不能为空')	
            }else{
				if(this.wtype==3){
					updatewxOne(s1,s2,s3,s4,s5).then( result =>{
					   if(result.describe=="操作成功！"){
						   	alert(result.describe);
						   	this.cleatForm();
				            this.$emit('submit');
					   }else if(result.describe=="token已过期"){
		            		alert("用户未登录，请先登录");
			            	this.$router.push('/Login');
		           	   }else{
					   	 	alert(result.describe+"请重试");
					   }
			        }).catch(error => {
			            console.log(error);
			            this.message="请求错误，请刷新";
			        });
				}
				if(this.wtype==4){
					updatejsOne(s1,s2,s3,s4,s5).then( result =>{
					   if(result.describe=="操作成功！"){
					   	alert(result.describe);
					   	this.cleatForm();
			            this.$emit('submit');
					   }else{
					   	alert(result.describe+"请重试");
					   }
			        }).catch(error => {
			            console.log(error);
			            this.message="请求错误，请刷新";
			        });
				}
			}
			

        },

        setStartingPoint(e) {
            this.x = e.clientX - this.node.offsetLeft
            this.y = e.clientY - this.node.offsetTop
            this.isCanMove = true
        },

        modalMove(e) {
            if (this.isCanMove) {
                this.node.style.left = e.clientX - this.x + 'px'
                this.node.style.top = e.clientY - this.y + 'px'
            } 
        },
        cancelMove() {
            this.isCanMove = false
        },	
        cleatForm(){
        	this.$refs.sort.value="";
        	this.$refs.name.value="";
        	this.value1=this.value;
        	tinyMCE.activeEditor.setContent("");
        }
    },
    
}
</script>
<style scoped>
.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 10;
}
.modal-container1 {
    background: #fff;
    border-radius: 10px;
    overflow-y:auto;
    position: fixed;
    top: 50%;
    width:1000px;
    height:95%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.modal-header {
    height: 50px;
    color: #000000;
    font-size:23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
    border-bottom: 1px solid #bac0cc;
}
.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 57px;
    border-top: 1px solid #ddd;
}
.modal-footer button {
    width: 80px;
    margin: 10px;
}
.maintitle{
	background: #bfddf7;
	color:#465e74;
	font-size: 18px;
	line-height: 40px;
	height: 40px;
	width: 100%;
	padding: 0.5px;
	border-radius: 8px 8px 0 0;
}
.maintitle2{
	background: #bfddf7;
	color:#465e74;
	font-size: 18px;
	line-height: 40px;
	height: 40px;
	width: 100%;
	padding: 0.5px;
	border-radius: 8px 8px 0 0;
}
.mainleft{
	padding-left:10px;
	height:40px;
}
.modal-main2 {   
    height: 360px;
    border: 1px solid darkgray;
    margin: 20px;
    border-radius: 8px;
}
.modal-main11 {   
    height: 30px;
/*    border: 1px solid darkgray;*/
    margin: 20px;
    /*border-radius: 8px;*/
}
.maincontxt{
	 padding: 15px ;
	 height: 100%;
}
.maincontxtall{
	 padding: 8px ;
	 height: 100%;
}
.mainpicc{
	width:35%;
	height: 130px;
	float: left;
	font-size: 17px;
	font:bold;
}
.mainpicneww{
	/*height:auto;*/
	width:100%;
	padding-top: 10px;
}
.mainpicdi{
	padding: 5px;	
}
.maimg{
	margin: 0 0 0 30px;
	padding: 3px;
	border: 1px solid  darkgray;
}
.maininput{
	width:100%;
	height: 50px;
}
.mainsinputele{
	float: left;
	/*width:50px;*/
	width:100px;
	line-height: 30px;
}
.maiesef{
	float: left;
	width:30px;
	/*width:40px;*/
	line-height: 40px;
}
.maieseff{
	margin-left: 5px;
}
.maiesef2{
	float: left;
	width:100px;
	/*width:40px;*/
	margin-left:10px;
	line-height: 40px;
}
.inptwitdti{
	width:400px;
	float:left;
}
.inptwitdti2{
	width:50px;
	float:left;
}
.btblselnew{
	margin: 10px 0 0 0;
	float: left;
	width: 80px;
	background: cornflowerblue;
	color:#ffffff;
	border-radius: 5px;
	border: 0;
	height: 30px;
	line-height: 30px;
	font-size: 16px;
}
textarea{
	width:100%;
	height: 79%;
	display: block;
}
.butst1{
		border-radius: 5px ;
		width: 150px;
		background: #dadae159;
		box-shadow: #a6a8b3 0px 0px 1px 1px ;
		outline: none;
	}
</style>