import axios from './axios'

export function getData(username,password) {
    return axios({
        url: '/login',
        method: 'get',
        params: {
           username:username,
            password:password
        }
    })
}
//气象服务数据
/*前台
 */
export function getAllWeather2() {
    return axios({
        url: '/page/get_all_data',
        method: 'get',
    })
}
/*后台--获取全部八种数据
 */
export function getAllWeather1(token) {
    return axios({
        url: '/admin/get_all_data',
        method: 'get',
        params: { token }
    })
}
/*后台--根据类型获取单一数据
 */
export function getEachWeather(type) {
    return axios({
        url: '/admin/getdatabytype',
        method: 'get',
        params: { type }
    })
}
/*后台--add
 */
export function addWeather(s3,s2,s4,s1,ttle,tp) {
    return axios({
       url: '/admin/add',
        method: 'post',
        params: {datacontent: s3, dataname: s2,
				  datarate: s4,
				  sort: s1,
				  title: ttle,
				  type: tp},
    })
}
/*后台--更新-数据初始化
 */
export function getOne(id,token) {
    return axios({
        url: '/admin/getdata',
        method: 'get',
        params: { token ,id}
    })
}
/*后台--更新
 */
export function updateOne(s3,s2,s4,s1,did,ttle,tp) {
    return axios({
        url: '/admin/update',
        method: 'post',
        params: {datacontent: s3, dataname: s2,
				  datarate: s4,
				  id: did,
				  sort: s1,
				  title: ttle,
				  type: tp}
    })
}
/*后台--del
 */
export function delWeather(ddd) {
	var ids="";
	for(var i=0;i<ddd.length;i++){
		if(i==(ddd.length-1)){
			ids+=ddd[i];
		}else{
			ids+=ddd[i]+",";
		}

	}
    return axios({
        url: '/admin/del',
        method: 'post',
        params: {ids}

    })
}
//气象服务案例
/*前台*/
export function getAllItem() {
    return axios({
        url: 'page/fw_getOrganData',
        method: 'get',
    })
}
export function getAllItemId(id) {
    return axios({
        url: 'page/fw_showConcent',
        method: 'get',
        params: { id: id },
    })
}
/*后台*/
export function getAllitem1() {
    return axios({
        url: '/admin/fw_getOrganData',
        method: 'get',
    })
}
/*后台--add
 */
export function addwxdemo(s1,s2,s3,s4) {
	//var pb=serialize(s4);
	//console.log(pb);
    return axios({
        url: '/admin/fw_saveOrgan',
        method: 'post',
        data: {
			sort: s1, publishtime: s2,
			title: s3,content: s4
		}
//      params: {sort: s1, publishtime: s2,
//      		 title: s3,content: s4},
    })
}
/*后台--更新-数据初始化
 */
export function getwxOne(id,token) {
    return axios({
        url: '/admin/fw_showConcent',
        method: 'get',
        params: { token ,id}
    })
}
/*后台--更新
 */
export function updatewxOne(s1,s2,s3,s4,s5) {
    return axios({
        url: '/admin/fw_updateOrgan',
        method: 'post',
        data: {
			sort: s1, publishtime: s2,
			title: s3,content: s4,id:s5
		}
//      params: {sort: s1, publishtime: s2,
//      		 title: s3,content: s4,id:s5}
    })
}
/*后台--del
 */
export function delwxr(ddd) {
	var ids="";
	for(var i=0;i<ddd.length;i++){
		if(i==(ddd.length-1)){
			ids+=ddd[i];
		}else{
			ids+=ddd[i]+",";
		}

	}
    return axios({
        url: '/admin/fw_deleteOrgan',
        method: 'post',
        params: {ids}

    })
}
//技术服务案例

/*前台*/
export function getAllTec() {
    return axios({
        url: 'page/js_getOrganData',
        method: 'get',
    })
}
export function getAllTecId(id) {
    return axios({
        url: 'page/js_showConcent',
        method: 'get',
        params: { id: id },
    })
}
/*后台*/
export function getAllTel1() {
    return axios({
        url: '/admin/js_getOrganData',
        method: 'get',
    })
}
/*后台--add
 */
export function addjsdemo(s1,s2,s3,s4) {
    return axios({
        url: '/admin/js_saveOrgan',
        method: 'post',
        data: {sort: s1, publishtime: s2,
        		 title: s3,content: s4
		}
//      params: {sort: s1, publishtime: s2,
//      		 title: s3,content: s4},
    })
}
/*后台--更新-数据初始化
 */
export function getjsOne(id,token) {
    return axios({
        url: '/admin/js_showConcent',
        method: 'get',
        params: { token ,id}
    })
}
/*后台--更新
 */
export function updatejsOne(s1,s2,s3,s4,s5) {
    return axios({
        url: '/admin/js_updateOrgan',
        method: 'post',
        data: {sort: s1, publishtime: s2,
        		 title: s3,content: s4,id:s5}
    })
}
/*后台--del
 */
export function deljsr(ddd) {
	var ids="";
	for(var i=0;i<ddd.length;i++){
		if(i==(ddd.length-1)){
			ids+=ddd[i];
		}else{
			ids+=ddd[i]+",";
		}

	}
    return axios({
        url: '/admin/js_deleteOrgan',
        method: 'post',
        params: {ids}

    })
}






