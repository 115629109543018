<template>
    <div>
        <Headerr />
        <div id="v-content" v-bind:style="{minHeight: Height+'px'}"><router-view /></div>
        <Footer class="top" :style="{background:'#4695E6'}"/>
    </div>
</template>

<script>
    export default {
        components: {
            //组件异步加载
            Headerr: resolve => {
                require(['components/Head'], resolve)
            },
        },
        data() {
            return {
                Height: 0
            }
        },
        mounted(){
            //动态设置内容高度 让footer始终居底   header+footer的高度是100
            this.Height = document.documentElement.clientHeight - 250;
            //监听浏览器窗口变化　
            window.onresize = ()=> {this.Height = document.documentElement.clientHeight -250}
        }
    }
</script>

<style  lang="scss"  scoped>
    .top{
        width:1198px;margin: 0 auto;
    }
</style>
