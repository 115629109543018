<template>
    <div class="whole" style="">
        <div class="bgwhit">
            <div class="title allhet">
                <div class="tileft allhet"></div>
                <div class="titext allhet">技术服务案例</div>
            </div>
            <div class="tibottom">
            </div>
            <div class="fivdiv">
                <p v-show="loadingShow" class="loading-box">
                    <!-- <img src="~assets/img/loading.gif" alt=""  srcset="">-->
                    <span>加载中...</span>
                </p>
                <p v-if="messageShow" style="text-align:center;">{{message}}</p>

                <table v-else  cellspacing="0" id="table" cellpadding="0">
                    <tr v-for="(item,index) in tableDataa" :key="index" class=" thei theii" :class="{activeColor:colorIndex===index}">
                        <td   style="width:80%" @click="dialog(item.id,index)">{{item.title}}</td>
                        <td style="width:20%" >{{item.publishtime}}</td>
                    </tr>
                </table>


                <el-dialog top="10px"  :title='"首页 / 技术服务案例 / " ' :visible.sync="dialogVisible" width="75%"  :modal-append-to-body='false'>
                    <div class="dialog-close">{{DataTablee.title}}</div>
                    <div style='text-indent: 29px;'class="dialog">来源：{{DataTablee.resource}} <span style='margin-left:50px;'>时间：{{DataTablee.inserttime}}</span>  </div>
                    <div  style="margin-top:30px;" v-html="DataTablee.content"></div>
                    <div class="dialog"></div>
                </el-dialog>
            </div>
        </div>
    </div>


</template>

<script>
    import {getAllTec,getAllTecId} from "network/home";
    export default {
        data(){
            return{
                tableDataa:[],
                DataTablee:[],
                loadingShow:false,
                messageShow: false,//信息提示语是否显示
                message: "",//信息提示语的内容
                dialogVisible: false,
                colorIndex:-1
            }
        },
        methods:{
            getData(){
                // this.loadingShow = true;
                // this.messageShow = true;
                // this.message = "";
                getAllTec().then( res =>{
                    if(res.statusCode == 0){
                        this.messageShow = false;
                        this.loadingShow = false;
                        this.tableDataa = res.datas[0];
                    }else{
                        this.messageShow = true;
                        this.message = "暂无数据";
                    }
                }).catch(error => {
                    console.log(error);
                });
            },

            dialog(ss,index){
                this.colorIndex = index;
                this.dialogVisible=true
                getAllTecId(ss).then( res =>{
                    if(res.statusCode == 0){
                        this.DataTablee = res.datas[0];
                    }
                }).catch(error => {
                    console.log(error);
                });
            }

        },
        mounted(){
            this.getData();
        }
    }
</script>

<style  lang="scss"  scoped>
    .whole{
        width:90%;margin: 0 auto;
        height: auto;padding: 15px 0 2px 0;
    }
    .allhet{
        height: 40px;
    }
    .title{
        width:100%;margin-top:5px;
        font-size: 23px;
    }
    .tileft{
        float:left;width:6px;
        background: #779DBF;
    }
    .titext{
        line-height: 38px;
        background:#779DBF;
        color:#ffe100;
        float:left;width:99%;margin-left:5px;font-weight: 800;
    }
    .tibottom{
        width:100%;height: 3px;;margin-top:3px;
        background: #779DBF;
    }
    .headcolor{
        background: #97B4CA;
        font-size: 21px;
    }
    .bgwhit{
        width: 100%;
        /*background: #FFFFFF;*/
        border-radius: 5px ;
        /*border: 2px solid  #d4e8f9;*/
    }
    .inptsty{
        height:37px;
        border-radius: 3px;
        border: 1px solid #d4e8f9;
    }
    .inptwitd{
        width:200px
    }
    .inptwitdtime{
        width:400px
    }
    .imged {
        margin-top:10px;
    }
    .weathertitle{
        background: #bfddf7;
        color:#465e74;
        font-size: 23px;
        line-height: 40px;
        height: 40px;
        padding-left:10px;
    }
    .butst1{
        border-radius: 5px ;
        width: 60px;
        background: #dadae159;
        box-shadow: #a6a8b3 0px 0px 1px 1px ;
        outline: none;
    }
    .btbl{
        width: 50px;
    }
    .btblsel{
        width: 80px;
        background: cornflowerblue;
        color:#ffffff;
        border-radius: 5px;
        border: 0;
        height: 30px;
        line-height: 30px;
        margin:0 0 0 20px;
        font-size: 16px;
    }
    .sediv{
        width:100%;height:45px;
    }
    .thidiv{
        text-align: center;
        width:80px ;float: right;margin: 7px 20px 0 0;
        /*border: 1px solid #d2cdcd;*/
        border-radius: 0 3px 3px 0;
        line-height: 35px;
        font-size: 19px;
    }
    .thirdiv{
        text-align: center;
        width:80px ;float: right;
        /*border: 1px solid #d2cdcd;*/
        margin: 7px 0 0 0;
        border-right: 0px solid #d2cdcd;
        border-radius: 3px 0 0 3px;line-height: 35px;
        font-size: 19px;
    }
    .fivdiv{
        /* margin: 8px;*/
        margin: 8px 0 0 0;
    }
    .seldiv{
        width:100%;float:left ;margin: 7px 0 0 15px;font-weight: 600;
    }
    .selctfont{
        height: 25px;font-size: 18px;
    }
    .thei{ height: 40px;}
    .theii{ height: 40px; font-size:19px;}
    table {
        /*text-align: center;*/
        width: 100%;
        height: auto;
        /* border: 2px solid #d2cdcd;*/
        border-collapse: collapse;
        tr {
            width: 100%;
            height: 30px;
            th {
                /*border: 1px solid #ededed;*/
                font-weight: 200;
            }
            td {
                text-align: left;
                font-weight: 100;
                border-bottom: dashed 2px #DDDDDD;
                /*border: 1px solid #ededed;*/
            }
            :hover{
                color: red;
                cursor:pointer;
            };
        }
    }
    .activeColor {
        color: red;
    }
    /*设置奇数行颜色*/
    table tr:nth-child(odd)
    {
        background: #ffffff;
    }
    .botm{
        width:100%;height:70px; margin:0;background-color:#e5f3ff;text-align:center
    }
    .dialog-close{
        width :100%;
        padding-top: 3px;
        padding-bottom: 2px;
        height: auto;
        font: 32px 'Simsun', '黑体';
        text-align: center;
        color: #002233;
    }
    .dialog{
        /*margin-left: 8px;*/
        width: 100%;
        height: 30px;
        line-height:30px;
        font-weight: bold;
        color:#ffe100;
        font: 14px/31px 'Simsun', '';
        background: rgb(70, 149, 230);
    }
</style>
