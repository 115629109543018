<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'app',
        mounted(){
            window.addEventListener('hashchange',()=>{
                var currentPath = window.location.hash.slice(1); // 获取输入的路由
                if(currentPath == "/WeatherUp" || currentPath == "/ProjectUp" ||currentPath == "/ItemServiceUp"||currentPath == "/TecServiceUp"){
                    this.$router.push("/Login"); // 动态跳转
                    alert("请用户先登录")
                }
            },false);
        },
    }
</script>

<style lang="scss">
    #app{
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;
        margin: 0 auto;


    }
    //黑白色
   // *{
     //   filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); -webkit-filter: grayscale(100%);
   // }
    html,body,#app{
        height: 100%;
        background: #f3f4f7;
    }
    * {
        margin: 0;
        padding: 0;
    }
    a {
        color:#383637;;
        text-decoration: none;
    }
    .el-dialog__body {
        padding: 30px 43px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }
    .el-dialog__title {
        font-weight: 600;
        line-height: 24px;
        font-size: 18px;
        color: #303133;
    }

</style>
